import { toast, ToastOptions } from 'react-toastify'
import { ObjectId } from 'lib/ObjectId'

const properties: ToastOptions = {
  position: `bottom-center`,
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
  theme: `dark`,
  icon: false,
}

export function notify(message: string, toastId: string = ObjectId()): void {
  toast(message, { ...properties, toastId })
}

export function notifySuccess(message: string, toastId: string = ObjectId()): void {
  toast.success(message, { ...properties, toastId })
}

export function notifyError(message: string, toastId: string = ObjectId()): void {
  toast.error(message, { ...properties, type: `error`, toastId, autoClose: 5000 })
}
